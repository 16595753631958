import React, {Fragment, useState} from 'react'
import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import BMPLocationDisplay from '../../DisplayComponents/BMPLocationDisplay';


export default function TakeMapSnapshot({reportState, saveReportForLater, handleChange, cancel, getMapImageCallback}){

    let {flexdata, loggedBMPs} = reportState;
    let [capturing, setCapturing] = useState(false)

    let captureMap = async () => {
    	setCapturing(true);
    	await handleChange("captureMapNow", true, "async")
    	setTimeout(() => {
    		handleChange("phase", "ReviewMapSnapshot")
    	}, 3000)
    }

	return(<>

		<InspectionWrapper
	      title="Capture Map Picture"
	      navButtons={[
	        {
	          className: "btn-secondary", 
	          onClick: cancel, 
	          label: <><i className="fa fa-angle-left mr-1"></i> BACK</>
	        },
	        {
	          className: "btn-outline-primary",
	          onClick: saveReportForLater,
	          label: "Save for Later"
	        },
	        {
	          className: "btn btn-primary",
	          onClick: captureMap,
	          disabled: capturing,
	          label: <><i class="fa fa-camera mr-1"></i> Capture</>
	        }
	      ]}
	    >

			<BMPLocationDisplay 
				bmpList={loggedBMPs || []} 
				reportState={reportState} 
				getMapImageCallback={getMapImageCallback}
				capture={reportState.captureMapNow}
				mapLoadedCallback={() => handleChange("mapCurrentlyLoaded", true)}
				mapUnloadedCallback={() => handleChange("mapCurrentlyLoaded", false)}
			/> 

		    {!capturing ? 
		    	<div className="col-12 mt-2 pb-4 mb-4 border-bottom">
		            <p className="mt-4 mb-0 fs-17"><strong>Ensure map is correctly positioned so that all BMPs are visible, then click the capture button below</strong></p>
		        </div> 
		    : <em><small>Capturing map image...</small></em> }

		</InspectionWrapper>
	</>)
}