import React, {Fragment, useState, useEffect} from 'react';
import {url} from "../../../../constants/index.js"

export default function StaticMapDisplay({reportState, handleChange}){

	return(<>

		<section class="section">

			<img src={url.backend + "/images/mapimages/" + reportState.mapImg} class="img-fluid img-thumbnail" alt="Earth Tight BMP Map" />
			<p><em><small>
				This map image will be displayed in PDF reports
			</small></em></p>

        </section>

	</>)

}
