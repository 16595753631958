import React, {Fragment} from 'react'
import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import StaticMapDisplay from '../../DisplayComponents/StaticMapDisplay';


export default function ReviewMapSnapshot({reportState, saveReportForLater, handleChange, cancel, getMapImageCallback}){

    let {flexdata, loggedBMPs} = reportState;


	return(<>

		<InspectionWrapper
	      title="Review Map Picture"
	      navButtons={[
	        {
	          className: "btn-secondary", 
	          onClick: () => handleChange("phase", "TakeMapSnapshot"), 
	          label: <><i className="fa fa-camera mr-1"></i> RE-TAKE</>
	        },
	        {
	          className: "btn-outline-primary",
	          onClick: saveReportForLater,
	          label: "Save for Later"
	        },
	        {
	          className: "btn btn-primary",
	          onClick: () => handleChange("phase", "InspectionSummary"),
	          label: <>Finish <i class="fa fa-angle-right ml-1"></i></>
	        }
	      ]}
	    >

        {reportState.mapImg ? 
			<StaticMapDisplay 
				reportState={reportState} 
			/> 
		:
			<em><small>No map image captured</small></em>
		}
		
		</InspectionWrapper>
	</>)
}