import React, {Fragment, useState} from 'react';
import BMPLocationDisplay from '../BMPLocationDisplay';
import StaticMapDisplay from '../../DisplayComponents/StaticMapDisplay';


export default function SummaryMapDisplay({reportState, handleChange, captureMapNow, getMapImageCallback}){

	let {loggedBMPs, canEditFlexdata, idsite, sitename, siteName} = reportState;
	let [showStaticMap, setShowStaticMap] = useState(true);


	const CaptureButton = () => {
		return <p>
			<button type="button" class="btn btn-round btn-small btn-primary mt-4" onClick={() => handleChange("phase", "TakeMapSnapshot")}>
				<i className="fa fa-camera mr-2 mt-n5"></i>
				{reportState.mapImg ? "Re-Capture Map Picture" : "Capture Map Picture" }
			</button>
		</p>
	}

	const ToggleButton = () => {
		if (!showStaticMap) return <p>
			<button type="button" class="btn btn-round btn-small btn-outline-primary mt-2 mb-4" onClick={() => setShowStaticMap(true)}>
				<i className="fa fa-map mr-2 mt-n5"></i>
				View Map Image
			</button>
		</p>
		else return <p>
			<button type="button" class="btn btn-round btn-small btn-outline-primary mt-2 mb-4" onClick={() => setShowStaticMap(false)}>
				<i className="fa fa-map mr-2 mt-n5"></i>
				View Interactive Map
			</button>
		</p>
	}


	//If site hasn't been selected yet
	if (!idsite || siteName || sitename){
		return 	<div className="col-12">
			<div className="col-12 pl-1 mb-2 clearfix">
				<p className="mt-4 mb-1 fs-20 float-left"><strong>Logged BMPs:</strong></p>
				{canEditFlexdata ? <button className="btn btn-xs btn-round btn-secondary fs-12 mt-4 mb-3 ml-4 px-4 float-right" onClick={() => handleChange("phase", "BMPInspection")}>LOG MORE</button> : null}

			</div>
		</div>
	}

	else return(<>

	<div className="col-12">
		<div className="col-12 pl-1 mb-2 clearfix">
			<p className="mt-4 mb-1 fs-20 float-left"><strong>Logged BMPs:</strong></p>
			{canEditFlexdata ? <button className="btn btn-xs btn-round btn-secondary fs-12 mt-4 mb-3 ml-4 px-4 float-right" onClick={() => handleChange("phase", "BMPInspection")}>LOG MORE</button> : null}
		</div>

		{/*If site has been selected and the interactive map is being displayed*/}
		{(!showStaticMap && reportState.mapImg) ? <>

			<BMPLocationDisplay 
				bmpList={loggedBMPs || []} 
				reportState={reportState} 
				getMapImageCallback={getMapImageCallback}
				capture={reportState.captureMapNow}
				mapLoadedCallback={() => handleChange("mapCurrentlyLoaded", true)}
				mapUnloadedCallback={() => handleChange("mapCurrentlyLoaded", false)}
			/> 
				
			<CaptureButton />
			<ToggleButton />
			

		{/*If site has been selected, the static map image exists, and is being displayed*/}

		</> : (reportState.mapImg && showStaticMap) ? <>

			<StaticMapDisplay
				reportState={reportState}
				handleChange={handleChange}
			/>

			<CaptureButton />
			<ToggleButton />
		
		{/*If site has been selected and the static map image does NOT exist, 
		and the interactive map is not being displayed/the static map mode has been selected*/}

		</> : <>

			<p className="mb-1"><em>Capture a map image to display to your stakeholders</em></p>
			 <CaptureButton />
		</>}
	</div>

	</>);
}
