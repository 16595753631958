import React, {Fragment, useEffect} from 'react';
import InspectionSummaryDisplay from '../../DisplayComponents/InspectionSummary/InspectionSummaryDisplay'
import createVerifiedBMPList from '../../utils/createVerifiedBMPList';


import {
  isMobileOnly
} from 'react-device-detect'

export default function InspectionSummary({reportState, handleChange, showEdit, saveReportForLater, sendSMS, getMapImageCallback}){


	useEffect(() => {
		if (reportState.initialLoadFinished){ //
			handleChange("verifiedBMPList", createVerifiedBMPList(reportState));
		}
	}, [reportState.initialLoadFinished])

	useEffect(() => {
		if (reportState.initialLoadFinished){ //
			handleChange("verifiedBMPList", createVerifiedBMPList(reportState));
		}
	}, [])

	const goToCaptureMapImage = async () => {
		handleChange("phase", "TakeMapSnapshot")
	}

	const onCloseOut = async () => {
		await handleChange("captureMapNow", true, "async")
		handleChange("showClosePopup", true)
	}

	const onSaveAndNotify = async () => {
		//await handleChange("captureMapNow", true, "async")
		handleChange('showFinalizePopup', true)
	}

	return(<>

		<InspectionSummaryDisplay
			reportState={reportState}
			handleChange={handleChange}
			captureMap={true}
			getMapImageCallback={getMapImageCallback}
			footerChild={() => {

				if (reportState.status == "setup"){
					return <div className={"col-12 mx-auto mb-6 pt-4 border-top" + (isMobileOnly ? " d-flex flex-column" : " flexbox")}>
						<a><label class="small lh-1"><em>You will no longer be able to log BMPs beyond this point.</em></label></a>
						
						<button 
							className={"btn btn-outline-primary" + (isMobileOnly ? " btn-block" : "")} 
							onClick={onSaveAndNotify}
						>
							SAVE &amp; NOTIFY
						</button>

						{!reportState.mapImg ? 
							<p><em><small><a href="#" onClick={goToCaptureMapImage}>Map image must be captured before inspection can be closed out</a></small></em></p>
						:
							<button 
								className="btn btn-primary" 
								onClick={onCloseOut}
								disabled={!reportState.mapImg}
							>
							CLOSE OUT INSPECTION
							</button>
						}

					</div>
				} else if (reportState.status == "open" || reportState.status == "closed"){
					return <>

					{!reportState.mapImg ?
						<div class="alert alert-warning position-static pt-2" role="alert">
  						<em>Map image must be captured before inspection can be closed out. <a href="#" class="alert-link" onClick={goToCaptureMapImage}>Click here</a> to capture map image right away</em>
						</div>

					: null}

					<div class="col-12 mx-auto mb-6 flexbox pt-4 border-top">
						<a><label class="small lh-1"><em>You will no longer be able to log corrective actions beyond this point.</em></label></a>
						
						<button 
							class="btn btn-outline-secondary" 
							onClick={saveReportForLater}
						>
							BACK TO REPORTS
						</button>
						<button 
							className="btn btn-primary" 
							onClick={onCloseOut}
							disabled={!reportState.mapImg}
						>
						CLOSE OUT INSPECTION
						</button>
			
					</div>
				</>} else { return null }

			}}
		/>

	</>)
}