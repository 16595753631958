import React, {Fragment, useState} from 'react';
import SummarySectionDisplay from './SummarySectionDisplay';
import SummaryListDisplay from './SummaryListDisplay';
import SummaryMapDisplay from './SummaryMapDisplay';
import BMPLocationDisplay from '../BMPLocationDisplay';
import LoggedBMPDisplay from '../LoggedBMPDisplay';
import _ from 'lodash';
import { Link } from 'react-router-dom'

import {
  isMobileOnly
} from 'react-device-detect'


export default function InspectionSummaryDisplay({handleBMPChange, reportState, handleChange, headerChild, footerChild, captureMapNow, getMapImageCallback}){

	let {sitename, siteName, workUnit, flexdata, loggedBMPs, status, canEditFlexdata, idsite} = reportState;


	return(<>
		<header className="section-header mb-0">
			<h2><strong>Inspection Summary</strong></h2>
		</header>

		<div className="section pt-4">
			<div className="container px-0">

				{headerChild ? headerChild() : null}

				<div className="row">

					<div class="text-left float-left border-bottom pb-5 mb-5 col-12">
						<Link class="btn btn-sm btn-secondary" to={() => '/app/inspection-reports'}><i class="fa fa-angle-left mr-1"></i>Inspection Reports</Link>
					</div>

					{(!idsite && status == "setup") ? (isMobileOnly ? 
						<div class="col-12 border-bottom pb-5">
              <p class="lead-3 p-0 mb-4 ml-2 pl-2">{workUnit.name}</p>
              <button class="btn btn-block btn-primary" onClick={() => handleChange("phase", "ProjectInfo")}>Start Inspection</button>
	          </div>
					:
						<div className="col-12 border-bottom pb-5 flexbox">
							<p className="lead-3 p-0 mb-0 ml-2 pl-2">{workUnit.name}</p>
							<button class="btn btn-primary" onClick={() => handleChange("phase", "ProjectInfo")}>Start Inspection</button>
						</div>
          ) : null}

					<div className="col-12 mx-auto mb-6 clearfix px-0">

						<SummarySectionDisplay
							title="Site Info"
							items={[
								{label: "Construction Site Name", value: (reportState.name || reportState.siteName || reportState.sitename)},
								{label: "Site ID #", value: flexdata.officialsiteid},
								{label: "Location, Municipality, County", value: flexdata.municipality},
								{label: "Contractor/Onsite Contact", value: flexdata.onsitecontact},
							]}
							showEdit={canEditFlexdata}
							edit={() => handleChange("phase", "ProjectInfo")}
							status={status}
							isMobileOnly={isMobileOnly}
						/>

						<SummarySectionDisplay
							title="Inspection Info"
							items={[
								{label: "Inspection Date", value: reportState.date, type: "date"},
								{label: "Inspection Type", value: reportState.type},
								{label: "Start Time", value: reportState.date, type: "time"},
								{label: "End Time", value: flexdata.inspectionend, type: "time"},
								{label: "Name(s) of Inspectors", value: flexdata.inspectionnames},
								{label: "Inspector Phone #", value: flexdata.inspectorphone, type: "phone"},
							]}
							showEdit={canEditFlexdata}
							edit={() => handleChange("phase", "InspectionInfo")}
							status={status}
							isMobileOnly={isMobileOnly}
						/>

						<SummarySectionDisplay
							title="Weather Conditions"
							items={[
								{label: "Temperature", value: flexdata.temperature},
								{label: "Soil Moisture", value: flexdata.soilmoisture, type: "checkbox"},
								{label: "Last Rainfull Date", value: flexdata.lastrainfalldate, type: "datetime"},
								{label: "Last Rainfall Depth", value: flexdata.lastrainfalldepth}
							]}
							showEdit={canEditFlexdata}
							edit={() => handleChange("phase", "WeatherAndSoil")}
							status={status}
							isMobileOnly={isMobileOnly}
						/>

						<SummarySectionDisplay
							title="Project Phase"
							items={[
								{label: "Phase of Construction", value: flexdata.currentphase},
								{label: "Report Based on Plan/Sheets:", value: flexdata.basedonsheets},
								{label: "Plan Sheets Dated", value: flexdata.sheetdate, type: "date"},
							]}
							showEdit={canEditFlexdata}
							edit={() => handleChange("phase", "ProjectPhase")}
							status={status}
							isMobileOnly={isMobileOnly}
						/>						

					</div>

				</div>



				<div className="row">

					<SummaryMapDisplay
						reportState={reportState}
						captureMapNow={captureMapNow}
						getMapImageCallback={getMapImageCallback}
						handleChange={handleChange}
					 />

					{/*<div className="col-12 clearfix my-2 border-bottom mb-6">
						<div className="col-4 flexbox float-right">
							<label className="small strong text-brand">FILTER</label>
							<label className="lead-4 strong text-success"><i className="fa fa-check-square mr-2 text-success"></i></label>
							<label className="lead-4 strong text-warning"><i className="fa fa-exclamation-triangle mr-2 text-warning"></i></label>
							<label className="lead-4 strong text-danger"><i className="fa fa-clock-o mr-2 text-danger"></i></label>
						</div>
					</div>*/}

					<div className="col-12 mx-auto p-0 m-0 my-lg-4 pb-4 mb-4 pt-lg-4 pb-lg-7">


					{reportState.loggedBMPs.length ? reportState.loggedBMPs.map((bmp, index) => {
						return <LoggedBMPDisplay
							passedBMP={bmp}
							key={bmp.idaction}
							handleChange={handleChange}
							handleBMPChange={handleBMPChange}
							reportState={reportState}
							isMobileOnly={isMobileOnly}
						/>
					}) : <span className="text-small text-light ml-6">No BMPs logged yet</span>}

					</div>
				</div>

				<SummaryListDisplay
					title="BMPs in Good Condition"
					label="BMPs that need corrective action are shown below."
					list={_.filter(flexdata.verifiedBMPList, {status: "good"})}
					showEdit={canEditFlexdata}
					edit={() => handleChange("phase", "BMPVerification")}
					status={status}
					isMobileOnly={isMobileOnly}
					icon={"ti ti-check text-success"}
				/>

				<SummaryListDisplay
					title="BMPs Currently Not Applicable"
					label="BMPs that need corrective action are shown below."
					list={_.filter(flexdata.verifiedBMPList, {status: "na"})}
					showEdit={canEditFlexdata}
					edit={() => handleChange("phase", "BMPVerification")}
					status={status}
					isMobileOnly={isMobileOnly}
					icon={"ti ti-na text-secondary"}
				/>

				<SummaryListDisplay
					title="Maintenance Crew for Corrective Actions"
					label="Text messages will be sent to these employees."
					labelClassName="small lh-1 ml-6"
					list={_.filter(reportState.employeesList, {selected: true})}
					showEdit={canEditFlexdata}
					edit={() => handleChange("phase", "InspectionWrapUp")}
					status={status}
					isMobileOnly={isMobileOnly}
					useColumns={true}
				/>


				{footerChild ? footerChild() : null}

			</div>

		</div>
	</>);
}